import { ModalStatus, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonIcon } from "@ionic/react";
import { CircularProgress, Stack } from "@mui/material";
import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { DocumentRequirementType } from "@src/appV2/Accounts/Documents";
import { useGetMissingRequirementsForDate } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirementsForDate";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useCheckPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useCheckPolicyAcknowledgement";
import { usePostPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/usePostPolicyAcknowledgement";
import { MandatoryBreakPolicyDialog } from "@src/appV2/Shifts/MandatoryBreakPolicy/MandatoryBreakPolicyDialog";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { USER_EVENTS } from "@src/constants/userEvents";
import { cashOutline, stopwatchOutline } from "ionicons/icons";
import { FC, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import { formatTimeNegotiationStartAndEndTime } from "./helper";
import {
  INegotiationButton,
  INegotiationCommitModalProps,
  NegotiationCommitType,
  NegotiationHistoryAction,
  NegotiationRateRole,
  NegotiationStatus,
  NegotiationType,
} from "./interfaces";
import { NegotiationCommitModal } from "./NegotiationCommitModal";
import { NegotiationTool } from "./negotiationTool";
import "./style.scss";
import { MissingDocumentsAlert } from "../dayView/Alerts";
import { ShiftItemCategory } from "../dayView/model";
import { useIsBreakPolicyAcknowledgementRequired } from "../mandatoryBreakPolicy/hooks/useIsBreakPolicyAcknowledgementRequired";
import { AppV2AccountRoutes } from "../routing/constant/appV2AccountRoutes";
import { useDefinedAgent } from "../store/helperHooks";

export const NegotiationButton: FC<INegotiationButton> = ({
  shiftDetails,
  workApproved,
  updateShiftViewRateNegotiation,
  isLoadingShiftBook,
  shouldOpenNegotiationTool,
  isRateNegotiationActive,
  shiftBookability,
  onBookShift,
}) => {
  const [shift, setShift] = useState(shiftDetails);
  const { showErrorToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const agent = useDefinedAgent();

  useEffect(() => {
    setShift(shiftDetails);
  }, [shiftDetails]);

  const [requestLoading, setRequestLoading] = useState(false);

  const updateShiftRateNegotiation = (negotiationData) =>
    setShift({ ...shift, rateNegotiation: negotiationData });

  const negotiationCommitModalState = useModalState();
  const [negotiationCommitModalProps, setNegotiationCommitModalProps] =
    useState<INegotiationCommitModalProps>({
      modalType: NegotiationCommitType.PROPOSE_NEW_RATE,
      closeModal: () => {},
      onCommit: () => {},
      requestLoading: false,
    });

  const [openNegotiationTool, setOpenNegotiationTool] = useState<boolean>(
    shouldOpenNegotiationTool ?? false
  );
  const [negotiationType, setNegotiationType] = useState<NegotiationType | undefined>(
    shift?.rateNegotiation?.type
  );

  const closeNegotiationTool = () => {
    setOpenNegotiationTool(false);
    if (updateShiftViewRateNegotiation && shift.rateNegotiation) {
      updateShiftViewRateNegotiation(shift);
    }
  };

  const {
    modalIsOpen: missingDocumentsAlertIsOpen,
    openModal: openMissingDocumentsAlert,
    closeModal: closeMissingDocumentsAlert,
  } = useModalState();

  const {
    mutateAsync: getMissingRequirementsForDate,
    data: missingRequirementsForDate,
    isLoading: isGetMissingRequirementsLoading,
    isSuccess: isGetMissingRequirementsSuccess,
  } = useGetMissingRequirementsForDate();

  const missingDocuments = isGetMissingRequirementsSuccess
    ? missingRequirementsForDate.data.hcpMissingRequirementsForDate.map((params) => ({
        reqId: params.reqId,
        name: params.name,
        visibleToHCP: params.visibleToHCP,
      }))
    : [];

  const missingDocumentsSet = new Set(missingDocuments);

  const breakPolicyAcknowledgementModalState = useModalState();

  const {
    isRequired: isBreakPolicyAcknowledgementRequired,
    noteId: breakPolicyNoteId,
    noteContent: breakPolicyNoteContent,
  } = useIsBreakPolicyAcknowledgementRequired({
    shift,
    facilityNotes: shift.facility?.facilityNotes ?? [],
  });

  const policyAcknowledgementEventPayload = {
    action: NoteAcknowledgementAction.START_RATE_NEGOTIATION,
    noteId: breakPolicyNoteId,
    shiftId: shift._id,
    facilityId: shift.facility?.userId,
    agentId: agent.userId,
  };

  const {
    mutateAsync: getBreakPolicyAcknowledgements,
    isLoading: isBreakPolicyAcknowledgementsLoading,
  } = useCheckPolicyAcknowledgement();

  const { mutate: postBreakPolicyAcknowledgement } = usePostPolicyAcknowledgement();

  const btnDetail = useMemo(() => {
    let details: {
      btnCustomProps: Record<string, unknown>;
      btnText: string;
    } = {
      btnCustomProps: {
        className: `negotiation-new-rate ${
          !isRateNegotiationActive ? "negotiation-new-rate-hidden" : ""
        }`,
        fill: "clear",
        color: "success",
      },
      btnText: "Propose new Rate",
    };
    const rateNegotiation = shift?.rateNegotiation;
    if (!rateNegotiation) {
      return details;
    }
    const disabledButtonProps = {
      color: "#828282",
      fill: "outline",
      style: {
        color: "#828282",
      },
    };
    const {
      payRate,
      start,
      end,
      createdByUserRole,
      action: lastAction,
    } = rateNegotiation.metadata.lastOffer;
    switch (rateNegotiation.status) {
      case NegotiationStatus.OPEN:
        if (createdByUserRole === NegotiationRateRole.WORKER) {
          const timeRangeContent =
            start && end && shift.facility?.tmz
              ? `: ${formatTimeNegotiationStartAndEndTime({
                  start,
                  end,
                  timezone: shift.facility?.tmz,
                })}`
              : "";
          const rateText = payRate ? `: $${payRate}/hr` : "";
          const buttonContent =
            "You proposed" +
            (rateNegotiation.type === NegotiationType.TIME ? timeRangeContent : rateText);
          details = {
            btnCustomProps: {
              fill: "outline",
              color: "success",
            },
            btnText: buttonContent,
          };
        } else {
          details = {
            btnCustomProps: {
              color: "success",
              fill: "solid",
            },
            btnText: `Reply to proposal: $${payRate}/hr`,
          };
        }
        break;
      case NegotiationStatus.CLOSED:
        if (lastAction === NegotiationHistoryAction.BOOKED_CONFLICTING_SHIFT) {
          details = {
            btnCustomProps: disabledButtonProps,
            btnText: `Ended by you`,
          };
        } else if (lastAction === NegotiationHistoryAction.BOOKED_BY_HCP) {
          details = {
            btnCustomProps: disabledButtonProps,
            btnText: `Booked by you`,
          };
        } else {
          details = {
            btnCustomProps: disabledButtonProps,
            btnText: `Booked by another Worker`,
          };
        }
        break;
      case NegotiationStatus.EXPIRED:
        details = {
          btnCustomProps: disabledButtonProps,
          btnText: `Expired before shift start`,
        };
        break;
      case NegotiationStatus.REJECTED:
        if (createdByUserRole === NegotiationRateRole.WORKER) {
          details = {
            btnCustomProps: disabledButtonProps,
            btnText: `Ended by you`,
          };
        } else {
          details = {
            btnCustomProps: disabledButtonProps,
            btnText: `Ended by Workplace`,
          };
        }
        break;
      case NegotiationStatus.ACCEPTED:
        details = {
          btnCustomProps: disabledButtonProps,
          btnText: `You're Working This Shift!`,
        };
        break;
    }
    return details;
  }, [isRateNegotiationActive, shift.facility?.tmz, shift?.rateNegotiation]);

  const closeDisplayCommitModal = () => {
    negotiationCommitModalState.closeModal();
  };

  const handleDisplayCommitModal = (props) => {
    setNegotiationCommitModalProps(props);

    negotiationCommitModalState.setModalStatus(
      props.isOpen ? ModalStatus.OPEN : ModalStatus.CLOSED
    );
  };

  const toolOpenedTrackEvent = () => {
    const history = shift?.rateNegotiation?.history || [];
    const trackProperties = {
      negotiationId: shift?.rateNegotiation?._id ?? "",
      shiftId: shift._id,
      new: history?.length === 0,
      type: negotiationType,
      proposals: history.filter(
        (historyItem) =>
          historyItem.action === NegotiationHistoryAction.PROPOSED ||
          historyItem.action === NegotiationHistoryAction.STARTED
      ).length,
    };

    logEvent(USER_EVENTS.NEGOTIABLE_MODAL_VIEWED, trackProperties);
  };

  const handleOpenNegotiation = async (negotiationTypeProp: NegotiationType) => {
    setNegotiationType(negotiationTypeProp);
    if (!shift?.rateNegotiation) {
      try {
        const missingRequirementsForDate = await getMissingRequirementsForDate({
          hcfId: shift.facilityId ?? "",
          date: shift.end ?? "",
          qualification: shift.agentReq ?? "",
        });

        if (missingRequirementsForDate?.data.hcpMissingRequirementsForDate.length > 0) {
          openMissingDocumentsAlert();
          return;
        }
      } catch {
        showErrorToast("Something went wrong while checking your documents. Please try again.");
        return;
      }

      if (isBreakPolicyAcknowledgementRequired) {
        const existingAcknowledgements = await getBreakPolicyAcknowledgements({
          noteId: breakPolicyNoteId,
          policyAcknowledgementAction: NoteAcknowledgementAction.START_RATE_NEGOTIATION,
        });

        if (existingAcknowledgements?.data.length === 0) {
          breakPolicyAcknowledgementModalState.openModal();
          logEvent(
            APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_VIEWED,
            policyAcknowledgementEventPayload
          );
          return;
        }
      }
    }

    setOpenNegotiationTool(true);
    toolOpenedTrackEvent();
  };

  if (isBreakPolicyAcknowledgementsLoading || isGetMissingRequirementsLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress size={20} />
      </Stack>
    );
  }

  return (
    <div className="negotiation-btn-container">
      {!workApproved && (
        <>
          {shift.shiftTimeNegotiationActive && !shift.rateNegotiation && (
            <IonButton
              className="negotiation-new-rate"
              shape="round"
              onClick={() => handleOpenNegotiation(NegotiationType.TIME)}
              fill="clear"
              color="success"
            >
              <IonIcon slot="start" icon={stopwatchOutline}></IonIcon>
              Propose an alternative time
            </IonButton>
          )}
          <IonButton
            className="shift-ion-button ion-button"
            shape="round"
            onClick={() =>
              handleOpenNegotiation(shift.rateNegotiation?.type || NegotiationType.RATE)
            }
            data-testid="negotiation-button"
            {...btnDetail?.btnCustomProps}
            {...(!isRateNegotiationActive ? { disabled: true } : {})}
          >
            {!shift.rateNegotiation && isRateNegotiationActive && (
              <IonIcon slot="start" icon={cashOutline}></IonIcon>
            )}

            {btnDetail?.btnText}
          </IonButton>
        </>
      )}
      {openNegotiationTool && (
        <NegotiationTool
          isOpen={openNegotiationTool}
          closeModal={closeNegotiationTool}
          shift={shift}
          showCommitModal={handleDisplayCommitModal}
          closeCommitModal={closeDisplayCommitModal}
          workApproved={workApproved}
          updateShiftRateNegotiation={updateShiftRateNegotiation}
          setRequestLoading={setRequestLoading}
          isLoadingShiftBook={isLoadingShiftBook}
          negotiationType={negotiationType}
          shiftBookability={shiftBookability}
          onBookShift={onBookShift}
        />
      )}

      <NegotiationCommitModal
        {...negotiationCommitModalProps}
        modalState={negotiationCommitModalState}
        closeModal={closeDisplayCommitModal}
        requestLoading={requestLoading}
      />

      <MissingDocumentsAlert
        shift={shift}
        expiringInFuture
        isOpen={missingDocumentsAlertIsOpen}
        missingDocuments={missingDocumentsSet}
        category={ShiftItemCategory.MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END}
        onConfirm={() => {
          if (missingDocumentsSet.size === 1) {
            const documentDetailsPath = generateDocumentDetailsPath({
              hcfId: shift.facilityId,
              requirement: missingDocumentsSet.values().next().value.reqId,
              requirementStatus: DocumentRequirementType.MISSING,
            });
            history.push(documentDetailsPath, {
              returnUrl: location.pathname,
            });
          } else {
            history.push(
              generatePath(AppV2AccountRoutes.HCF_DOCUMENT_BY_ID_AND_NAME, {
                hcfId: shift.facilityId,
                hcfName: shift.facility?.name,
              }),
              {
                returnUrl: location.pathname,
              }
            );
          }
        }}
        onDidDismiss={closeMissingDocumentsAlert}
      />

      {isBreakPolicyAcknowledgementRequired && isDefined(breakPolicyNoteContent) && (
        <MandatoryBreakPolicyDialog
          modalState={breakPolicyAcknowledgementModalState}
          contentLines={breakPolicyNoteContent.split("\n")}
          acknowledgementAction={NoteAcknowledgementAction.START_RATE_NEGOTIATION}
          onClose={() => {
            logEvent(
              APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_CANCELLED,
              policyAcknowledgementEventPayload
            );
          }}
          onContinue={async () => {
            postBreakPolicyAcknowledgement({
              policyAcknowledgementAction: NoteAcknowledgementAction.START_RATE_NEGOTIATION,
              noteId: breakPolicyNoteId ?? "",
            });
            logEvent(
              APP_V2_USER_EVENTS.MANDATORY_BREAK_POLICY_ACCEPTED,
              policyAcknowledgementEventPayload
            );
            setOpenNegotiationTool(true);
            toolOpenedTrackEvent();
          }}
        />
      )}
    </div>
  );
};
